<template>
  <div class="text-tekstovyy/40 text-3.5 font-500">
    <NuxtLink
      v-for="link in breadcrumbs"
      :key="`breadcrumb-${link.title}`"
      :to="link?.path"
      class="after:mx-1 after:content-['>'] last:text-firmennyy/60 last:pointer-events-none last:after:content-none"
    >
      {{ link?.title }}
    </NuxtLink>
  </div>
</template>

<script lang="ts" setup>
import type { BreadcrumbsType } from '~/types/commons';
import type { ListItem } from 'schema-dts';
import { useRequestURL, useJsonld } from '#imports';

const props = defineProps<{
	breadcrumbs: BreadcrumbsType[];
}>();

const { host } = useRequestURL();

const itemList: ListItem[] = props.breadcrumbs.map((t, idx) => ({
  '@type': 'ListItem',
  position: idx + 1,
  item: { '@id': `${host}${t.path}`, name: t.title }
}));

useJsonld(() => ({
  '@context': 'https://schema.org',
  '@type': 'BreadcrumbList',
  itemListElement: itemList
}));
</script>
